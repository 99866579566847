import { withTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

const Unauthorized = ({i18n, t}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/dashboard'

  return (
    <div className='error'>
      <h1>{t('error.unauthorized.title')}</h1>
      <p>{t('error.unauthorized.message')}</p>
      <button type='button' className='cancel' onClick={() => navigate(from)}>
        {t('buttons.back')}
      </button>
    </div>
  )
}

export default withTranslation('ui')(Unauthorized)
