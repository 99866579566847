import { useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'

import dates from '../../utils/dates'
import Table from '../../components/table'

import UserEditPopup from './UserEditPopup'
import UserService from '../../services/UserService'

function Users ({ i18n, t }) {
  const [searchPattern, setSearchPattern] = useState('')

  const [editPopupContent, setEditPopupContent] = useState({})
  const [editPopupState, setEditPopupState] = useState(false)

  const tableRef = useRef(null)

  const queryClient = new QueryClient()

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: t('users.table.header.id')
    },
    {
      accessorKey: 'login',
      header: t('users.table.header.login')
    },
    {
      accessorKey: 'name',
      enableSorting: false,
      header: t('users.table.header.name')
    },
    {
      accessorKey: 'roles',
      cell: props => (props.getValue().sort().reduce((acc, val) => { return acc + ', ' + val })),
      enableSorting: false,
      header: t('users.table.header.roles')
    },
    {
      accessorKey: 'lastSeen',
      cell: props => (dates.format(i18n, props.getValue(), t('users.table.no_date'))),
      header: t('users.table.header.last_connection')
    },
    {
      cell: (props) => (
        <div
          className='pointer'
          onClick={() => {onOpenEditor(props.row.original.id)}}>
          <p>{t('administrators.table.details')}</p>
          <box-icon class='icon' name='plus-circle'></box-icon>
        </div>
      ),
      header: t('users.table.header.actions')
    },
    {
      accessorKey: 'delete',
      cell: (props) => (
        <div
          className='pointer'
          onClick={() => onDeleteAdministrator(props.row.original.id)}>
          <box-icon type="solid" name="trash-alt"></box-icon>
        </div>
      ),
      header: t('objects.table.header.action')
    }

  ], [i18n, t])

  async function usersFetcher (parameters) {
    let query = {}

    console.log(parameters)

    if (parameters.pageFilter.trim().length > 0) {
      query['sift:login'] = parameters.pageFilter.trim()
    }

    (parameters.pageSortBy || []).forEach(sort => {
      query[`order:${sort.id}`] = sort.desc ? 'desc' : 'asc'
    })

    query['page'] = parameters.pageIndex + 1
    query['size'] = parameters.pageSize

    /* Select only the administrators. */
    query['sift:role'] = 'ADMIN,ASS,SUPER_ADMIN'

    try {
      return await UserService.getUsers(query)
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  }

  function onCreateAdministrator (properties) {
    UserService.addUser(properties).then(resources => {
      tableRef.current.reload()
    })
  }

  function onDeleteAdministrator (id) {
    UserService.deleteUser(id).then(resources => {
      tableRef.current.reload()
    })
  }

  function onEditAdministrator (properties) {
    setEditPopupState(false)
    if (properties !== null) {
      UserService.updateUser(properties.id, properties).then(resource => {
        tableRef.current.reload()
      })
    }
  }

  function onOpenEditor (id) {
    UserService.getUser(id).then((resource) => {
      setEditPopupContent(resource)
      setEditPopupState(o => !o)
    }).catch((error) => {
      console.log(error)
    })
  }

  const onSearchUpdate = (e) => {
    setSearchPattern(e.target.value)
  }

  return (
    <>
      <h1>{t('administrators.title')}</h1>
      <div className='head-tools'>
        <UserEditPopup
          isAdmin={true}
          isCreation={true}
          callback={onCreateAdministrator}
          trigger={<button>{t('administrators.create.button')}</button>} />
        <UserEditPopup
          isAdmin={true}
          isCreation={false}
          callback={onEditAdministrator}
          control={editPopupState}
          values={editPopupContent} />
        <input
          onChange={onSearchUpdate}
          placeholder={t('administrators.search.placeholder')}
          type='text'
          value={searchPattern}/>
      </div>
      <div>
        <QueryClientProvider client={queryClient}>
          <Table
            ref={tableRef}
            columns={columns}
            feeder={usersFetcher}
            keyword={searchPattern} />
        </QueryClientProvider>
      </div>
    </>
  )
}

export default withTranslation('ui')(Users)
