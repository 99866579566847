import Popup from 'reactjs-popup'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import FormikControl from '../../components/formikComponents/FormikControl'
import * as Yup from 'yup'

import { ADMIN_ROLES, USER_ROLES } from '../../utils/userRoles'

import 'boxicons'

const UserEditComponent = ({isAdmin, isCreation, callback, control, t, trigger, values}) => {

  const [dummyState, setDummyState] = useState(0);

  /* No validation for now */
  const validationSchema = Yup.object({
  })

  /* Set up the initial values if called without them. */
  var values = {
    roles : []
  }

  values.roles = isAdmin ? ADMIN_ROLES : USER_ROLES
  values.roles.map((role,index)=>{
    if (!isAdmin)
      if (index < 8)
        {
          values.roles[index].checked = true; 
        }
        else
        {
          values.roles[index].checked = false;
        }
    else
      if (index === 0)
        values.roles[index].checked = true; 

  })

  /*
   * `trigger' is used by the creation popup, which is associated to a button.
   * The edit popup is controlled by a state, so it must notify its closing. It
   * does this using the given callback with no data.
   */
  return (
    <Popup closeOnDocumentClick modal nested open={control} onClose={() => {
      !isCreation && callback(null)
    }} trigger={trigger}>
      {close => (
        <div className='popup'>
          <h1 className='popup-title'>
            {t(isCreation ? 'users.popup.title.generate' : 'users.popup.title.edit')}
          </h1>
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={(data) => {
              callback(data)
              close()
            }}>
            {(formik) => (
              <Form>
                <FormikControl
                  control='input'
                  label={t('users.popup.name')}
                  name='name'
                  type='text'/>

                <FormikControl
                  control='input'
                  label={t('users.popup.email')}
                  name='login'
                  type='email'/>

                <FormikControl
                  control='input'
                  label={t('users.popup.password')}
                  name='password'
                  type='password'/>

                <FormikControl
                  control='checkbox'
                  label={t('users.popup.roles')}
                  name='roles'
                  options={isAdmin ? ADMIN_ROLES : USER_ROLES}
                />


                <div className='button-row'>
                  <button type='button' className='secondary' onClick={close}>
                    {t('buttons.cancel')}
                  </button>
                  <button type='submit' className='button-ota'>
                    {t(isCreation ? 'buttons.generate' : 'buttons.save')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withTranslation('ui')(UserEditComponent)
